import {React, useState} from 'react'
import guest from '../Images/karan_sir.png';
// import './SpeakerCard.css';


function SpeakerCard(props) {
  const file_key=Object.keys(props.image);
  const file= props.image[file_key];
  

  return (

    
  <div class="card mb-3 shadow-lg p-3 mb-5 bg-white rounded my-5" style={{maxWidth: "480px"}}>
  <div class="row g-0">
    <div class="col-md-4">
      <img src={file} class="img-fluid rounded-circle my-3" alt="..." />
    </div>
    <div class="col-md-8">
      <div class="card-body">
        <h5 class="card-title my-3">{props.name}</h5>
        <p class="card-text">{props.designation}</p>
        <p class="card-text">{props.university}, {props.state}</p>
        <p className="card-text">{props.country}</p>
        {/* <p class="card-text"><small class="text-body-secondary">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ratione iusto error, in doloribus eum dicta excepturi inventore amet facilis, quas cumque sequi maiores provident quia dolorum omnis blanditiis nisi quos.</small></p> */}
      </div>
    </div>
  </div>
</div>
  


   );
}

export default SpeakerCard