import React from 'react'
import Ieee_banner from '../Ieee_banner/Ieee_banner'
import { Link } from 'react-router-dom'

function Tracks() {
  return (
    <div className="container my-5">
      <div className="row">
              <div className="col-sm-6 mb-3 mb-sm-0">
                <div className="card border-0">
                  <div className="card-body">
                    <h1 className="card-title border-bottom ">Paper Submission</h1>
                    {/* <p className="card-text my-4" style={{textAlign:'justify'}}>The Original unpublished Research Papers, Articles & Working papers having maximum length 8 pages on the topics related to the theme are invited for presentation/publication in the conference proceedings.</p>

                    <ol >
                      <li className='my-3' style={{textAlign:'justify'}}>Kindly ensure that your paper is formatted as per IEEE Guidelines (not exceeding 8 pages written in A4 size). Please refer the attached IEEE template for preparation of your paper.</li>

                      <li className='my-3' style={{textAlign:'justify'}}>All papers must be submitted online via Microsoft CMT Submission Portal</li>

                     <li className='my-3' style={{textAlign:'justify'}}>All submissions will be thoroughly peer-reviewed by experts based on originality, significance and clarity.</li>

                     <li className='my-3' style={{textAlign:'justify'}}>Only papers presenting original content with novel research results or successful innovative applications will be considered for publication in the conference proceedings (as chapters in edited volumes of IEEE Series).</li>

                    </ol>

                    <h2 className="card-title border-bottom my-4 ">Plagiarism Policy</h2>

                    <ol>
                      <li className="my-3" style={{textAlign:'justify'}}>The paper prior to submission should be checked for plagiarism from licensed plagiarism softwares like Turnitin/iAuthenticate etc. The similarity content should not exceed 20% (in any case either self contents or others). Further, you have to strictly implement the following ethical guidelines for publication:</li>
                      <li className="my-3" style={{textAlign:'justify'}}>Any form of self-plagiarism or plagiarism from others' work(s) should not be there in an article. If any model / concept / figure / table / data / conclusive comment by any previously published work is used in your article, you should properly cite a reference to the original work.</li>
                      <li className="my-3" style={{textAlign:'justify'}}>Also language of explaining it should not be same as language of the work from which you have adopted it. If you are using any copyrighted material, you should acquire prior permission from the copyright holder.</li>
                      
                    </ol> */}

                    <p className="card-text my-4" style={{textAlign: "justify"}}>NetCrypt 2025 is dedicated to fostering high-quality research in the field of Computational Sciences. This conference serves as a platform for sharing information, introducing innovative technologies and advancements, and exploring future directions, strategies, and priorities within this domain. NetCrypt 2025 undoubtedly gathers influential thought leaders to engage in discussions and deliberations on the most critical issues, delivering timely insights across various sectors and exemplary practices. The exchange of ideas in areas of interest lays the groundwork for future collaborations on a global scale. Among the key advantages of attending this conference is the opportunity to stay updated on the latest developments in technology, which is essential for academic sustainability. The conference ensures participants are well-informed about the most recent discoveries and advancements in the field.</p>



                    <h2 className="card-title border-bottom my-4 ">Steps for Submission</h2>
                    <ul>
                      <li className="my-3" style={{textAlign:'justify'}}>Create your CMT microsoft account </li>
                      <li className="my-3" style={{textAlign:'justify'}}>Login through your CMT microsoft account </li>
                      <li className="my-3" style={{textAlign:'justify'}}>Search for NETCRYPT2025 / Click on the below link to redirect
                          <a href="https://cmt3.research.microsoft.com/NETCRYPT2025" className="btn btn-primary mx-5 my-3 border-0" target="_blank" style={{background:"rgb(0,126,123)"}}>NETCRYPT 2025</a>
                       </li>
                      <li className="my-3" style={{textAlign:'justify'}}>Submit Your Paper.</li>
                      <li className="my-3" style={{textAlign:'justify'}}>Wait for acceptance.</li>
                      <li className="my-3" style={{textAlign:'justify'}}>Submit your CRC.</li>
                    </ul>

                    <Link to='/registration' href="#" className="btn btn-outline-primary">Registration</Link>
                  </div>
                </div>
              </div>

              <Ieee_banner />

          </div>
    </div>
)}

export default Tracks