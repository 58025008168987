import React from 'react'
import Ieee_banner from '../Ieee_banner/Ieee_banner'
import  location from '../Images/location.png'


function Contacts() {
  return (
    <div className="container my-5">
    <div className="row">
      <div className="col-sm-6 mb-3 mb-sm-0">
            <div className="card border-0">
              <div className="card-body">
                <h1 className="card-title border-bottom">Contacts</h1>

                <div className="card border-0 mb-3" style={{"width": "18rem"}}>
                  <div className="card-body">
                    <h4 className="card-text border-bottom my-3">Chair NetCrypt 2025 </h4>
                    <h5 className="card-title">Phone:</h5>
                    <p className="card-text mx-3">+91 9560402070</p>
                    <h5 className="card-title">Email:</h5>
                    <p className="card-text mx-3">chair@netcrypt.org.in</p>
                    <p className="card-text mx-3">netcrypt2024@gmail.com</p>
                  </div>
                </div>

                

                <h3 className="card-title border-bottom">Address:</h3>

                <img src={location} alt="" height="30px" width="25px" />
                <p className="card-text" style={{textAlign:'justify'}}> SCHOOL OF COMPUTER AND SYSTEMS SCIENCES</p>
                <p className="card-text" style={{textAlign:'justify'}}>  JAWAHARLAL NEHRU UNIVERSITY, NEW DELHI, 110067</p>

                <div className="ratio ratio-16x9 my-5">
                     <iframe className='my-5' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.9280434108077!2d77.1628792752856!3d28.541882475714335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1dc476d79b4d%3A0xa2365e11e7b695fd!2z4KS44KWN4KSV4KWC4KSyIOCkkeCkq-CkvCDgpJXgpILgpKrgpY3gpK_gpYLgpJ_gpLAg4KSU4KSwIOCkuOCkv-CkuOCljeCkn-CkruCljeCkuCDgpLjgpL7gpIfgpILgpLjgpYfgpLg!5e0!3m2!1shi!2sin!4v1708405999442!5m2!1shi!2sin" width="600" height="450" style={{"border":"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>

                
                
              </div>
            </div>
          </div>

          <Ieee_banner />


          
    </div>
  </div>
  )
}

export default Contacts