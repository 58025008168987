import React from 'react'
import Ieee_banner from '../Ieee_banner/Ieee_banner'

function Registration() {
  return (
    <div className="container my-5">
    <div className="row">
      <div className="col-sm-6 mb-3 mb-sm-0">
            <div className="card border-0">
              <div className="card-body">
                <h1 className="card-title border-bottom">Registration fees</h1>
                <p className="card-text" style={{textAlign:"justify"}}>The registration fees cover the cost of food and beverages, a bag, a brochure, schedules, a pen, a note pad, and a pen drive for a 3-day accommodation. <strong>The final registration deadline is yet to be determined March 25, 2025</strong></p>

                <table className="table table-striped table-hover my-5">
                    <thead>
                      <tr>
                        <th scope="col"> </th>
                        {/* <th scope="col">Non IEEE Member</th> */}
                        
                  
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Non-IEEE Professional </th>
                        <td>&#8377; 9000 + GST (18%) </td>
                        
                        
                      </tr>

                      <tr>
                        <th scope="row">  IEEE Professional Members</th>
                        <td>&#8377; 8000 + GST (18%)</td>
                        
                        
                      </tr>

                      <tr>
                        <th scope="row">Non-IEEE Students</th>
                        <td>&#8377; 7000 + GST (18%)</td>
                        
                        
                      </tr>

                      <tr>
                        <th scope="row">  IEEE Student Members</th>
                        <td>&#8377; 5000 + GST (18%)</td>
                        
                        
                      </tr>

                      <tr>
                        <th scope="row">Industry Participants</th>
                        <td>&#8377; 1000 + GST (18%)</td>
                        
                  
                      </tr>

                      <tr>
                        <th scope="row">Non-IEEE Foreign Participants</th>
                        <td>&#x24; 350 + GST (18%)</td>
                        
                  
                      </tr>

                      <tr>
                        <th scope="row">  IEEE Foreign Participants</th>
                        <td>&#x24; 250 + GST (18%)</td>
                        
                  
                      </tr>
                    </tbody>
                </table>
                
                {/* <table className="table table-striped table-hover my-5">
                    <thead>
                      <tr>
                        <th scope="col"> </th>
                        <th scope="col">Non IEEE Member</th>
                        <th scope="col">IEEE Member</th>
                  
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Scientific committee members</th>
                        <td>&#8377; 7000 </td>
                        <td>&#8377; 6000</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">Standard registration</th>
                        <td>&#8377; 6500</td>
                        <td>&#8377; 5500</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">Students</th>
                        <td>&#8377; 6000</td>
                        <td>&#8377; 5000</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">Companies/industries</th>
                        <td>&#8377; 7000</td>
                        <td>&#8377; 6000</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">Additional paper (≥3)</th>
                        <td>&#8377; 6000</td>
                        <td>&#8377; 5000</td>
                  
                      </tr>
                    </tbody>
                </table> */}

                {/* <table className="table table-striped table-hover my-5">
                    <thead>
                      <tr>
                        <th scope="col"> </th>
                        <th scope="col">International Non IEEE Member</th>
                        <th scope="col">International IEEE Member</th>
                  
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Scientific committee members</th>
                        <td>&#x24; 350 </td>
                        <td>&#x24; 300</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">Standard registration</th>
                        <td>&#x24; 250</td>
                        <td>&#x24; 200</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">Students</th>
                        <td>&#x24; 200</td>
                        <td>&#x24; 180</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">Companies/industries</th>
                        <td>&#x24; 350</td>
                        <td>&#x24; 250</td>
                        
                      </tr>

                      <tr>
                        <th scope="row">Additional paper (≥3)</th>
                        <td>&#x24; 200</td>
                        <td>&#x24; 180</td>
                  
                      </tr>
                    </tbody>
                </table> */}
                
              </div>
            </div>
          </div>

          <Ieee_banner />


          
    </div>
  </div>
  )
}

export default Registration