import React from 'react'
import Ieee_banner from '../Ieee_banner/Ieee_banner'
import SpeakerCard from './SpeakerCard'
import bharat from '../Images/bharat.jpg';
import Vinay from '../Images/Vinya.jpg';
import Rajeev from '../Images/Rajeev_sir.jpg'
import Sanjay from '../Images/sanjay.jpg';
import Sriniwas_sir from '../Images/sriniwas_sir.jpg';
import binod_sir from '../Images/Binod_sir.jpg';
import bimal_sir from '../Images/bimal_sir.jpg';
import Doja_sir from '../Images/M.N_doja_sir.jpg';
import Mathapati_sir from '../Images/mathapati_sir.jpg';
import fabrizio_sir from '../Images/fabrizio.baiardi.jpg'
import gadadhar_sir from '../Images/gadadhar.png';
import muhmmad_sir from '../Images/muhammad_sir.png'
import Ashok_sir from '../Images/Ashok_sir.png'
import Rafik_sir from '../Images/rafik_sir.png'
import Sehzad_sir from '../Images/Sehzad_sir.png'
import pascal_sir from '../Images/Pascal_sir.png'
import mahmud_sir from '../Images/Mahmud.png'
import Arijit_sir from '../Images/Arijit_sir.png'
import Khalid_sir from '../Images/Khalid_sir.png'
import Liyange_sir from '../Images/Liyanage_sir.png'
import Dherendar_sir from '../Images/Dheerendar_sir.png'

function Speakers() {

  const speaker= [
    // {
    //   proffessor: "Prof. Bharat K. Bhargava",
    //   designation: "Professor",
    //   University: "Purdue University",
    //   State: "Indiana",
    //   description: "Bharat K. Bhargava (Life Fellow, IEEE) is a Professor of computer science at Purdue University. He is the Founder of the IEEE Symposium on Reliable and Distributed Systems.",
    //   profile: "https://www.cs.purdue.edu/homes/bb/",
    //   Country: "USA",
    //   image: bharat
    // },

    {
      proffessor: "Prof. Bimal Kumar Roy",
      designation: "Professor",
      University: "Indian Statistical Institute ",
      State: " Kolkata",
      description: "Prof. Bimal Kumar Roy is a researcher of international standing. His primary research interest involves all aspects of Statistics and its applications. ",
      profile: "https://www.isical.ac.in/~bimal/",
      Country: "India",
      image: bimal_sir
    },

    {
      proffessor: "Prof. Rajeev Tripathi",
      designation: "Professor",
      University: " Motilal Nehru National Institute of Technology Allahabad",
      State: "Prayagraj",
      description: "Prof. Rajeev Tripathi received his B.Tech from the University of Allahabad in 1986, M. Tech in 1992 and PhD in 1998. He became a faculty member at MNNIT Allahabad in 1988 and has been a professor since 2005. From 2002 to 2004, he was professor in St. Augustine in Trinidad (WI) at the University of the West Indies.",
      profile: "http://www.mnnit.ac.in/profile/rt",
      Country: "India",
      image: Rajeev
    },

    {
      proffessor: "Prof. Fabrizio Baiardi",
      designation: "Professor",
      University: "University of Pisa",
      State: "Pisa",
      description: "Fabrizio Baiardi graduated in Computer Science at Università di Pisa where is a Full Professor with Dipartimento di Informatica where he has chaired the degree on security of ICT infrastructures. His main research interests in the computer security field are formal approaches to risk assessment and management of complex ICT infrastructures",
      profile: "http://www.mnnit.ac.in/profile/rt",
      Country: "Italy",
      image: fabrizio_sir
    },

    // {
    //   proffessor: "Prof. Sri Niwas Singh",
    //   designation: "Honourable Director",
    //   University: "Atal Bihari Vajpayee- Indian Institute of Information Technology and Management",
    //   State: "Gwalior",
    //   Country: "India",
    //   image: {Sriniwas_sir}
    // },

  
    {
      proffessor: "Prof. Muhammad Khurram Khan",
      designation: "Professor",
      University: "King Saud University",
      State: "Kanpur",
      description: "Prof. Muhammad Khurram Khan is a global thought leader and influencer in cybersecurity. He is a Professor of Cybersecurity at the Center of Excellence in Information Assurance (CoEIA), King Saud University, Kingdom of Saudi Arabia.",
      profile: "https://www.professorkhurram.com/",
      Country: "Saudi Arabia.",
      image: muhmmad_sir
    },

    // {
    //   proffessor: "Prof. Vinay Kumar Pathak",
    //   designation: "Honourable Vice Chancellor",
    //   University: "Chhatrapati Shahu Ji Maharaj University",
    //   State: "Kanpur",
    //   description: "Prof. Vinay Kumar Pathak served as Vice Chancellor, UOU, Uttarakhand, VMOU, Kota,Rajasthan, Rajasthan Technical University, Kota, Rajasthan (AdditionalCharge), Dr. A.P.J. Abdul Kalam Technical University, Lucknow, UttarPradesh, Khwaja Moinuddin Chishti Language University.",
    //   profile: "https://www.bing.com/ck/a?!&&p=31ddf170c61d0995JmltdHM9MTcxODMyMzIwMCZpZ3VpZD0wNDg0NTcxZC00OGRlLTYwYWMtMTljYy00NmJjNDk3MzYxNTgmaW5zaWQ9NTIxOQ&ptn=3&ver=2&hsh=3&fclid=0484571d-48de-60ac-19cc-46bc49736158&psq=Prof.+Vinay+Kumar+Pathak&u=a1aHR0cHM6Ly9ha3R1LmFjLmluL1Byb2YuJTIwVmluYXklMjBLdW1hciUyMFBhdGhhay5odG1s&ntb=1",
    //   Country: "India",
    //   image: Vinay
    // },

    {
      proffessor: "Prof. Pascal LORENZ",
      designation: "Professor",
      University: "University of Haute Alsace ",
      State:"CA",
      description:"Pascal Lorenz (lorenz@ieee.org) received his M.Sc. (1990) and Ph.D. (1994) from the University of Nancy, France. Between 1990 and 1995 he was a research engineer at WorldFIP Europe and at Alcatel-Alsthom.",
      Country: " France",
      profile: "http://grtc.uha.fr/lorenz.html",
      image: pascal_sir
    },

    {
      proffessor: "Prof. Shivakumar Mathapathi",
      designation: "Professor",
      University: "UC San Diego Extn ",
      State:"CA",
      description:"Shivakumar Mathapathi is an adjunct faculty member at Northeastern University as well at UC San Diego, Extension; he is also teaching at regional community academia including ethnically diverse institutions such as Ohlone and De-Anza College in California.",
      profile: "https://www.khoury.northeastern.edu/people/shivakumar-mathapathi/",
      Country: "USA",
      image: Mathapati_sir
    },

    {
      proffessor: "Prof. Ashok Kumar Das",
      designation: "Professor",
      University: "Indian Institute of Information Technology, Hyderabad",
      State: " Telegana",
      description: "Prof. Ashok Kumar Das currently working as a full Professor effective from 1 January 2023 in the Center for Security, Theory and Algorithmic Research (Department of Computer Science and Engineering) of the International Institute of Information Technology (IIIT), Hyderabad, India.",
      profile: "https://www.iiit.ac.in/people/faculty/ashokkdas/",
      Country: "India",
      image: Ashok_sir
    },

    {
      proffessor: "Prof. M.N. Doja",
      designation: "Honourable Director",
      University: "Indian Institute of Information Technology ",
      State: " Sonepat",
      description:"Prof(Dr) Shri M.N. Doja joined as Director of Indian Institute of Information Technology, Sonepat in 2019. Along with being the Director of IIIT Sonepat, Dr. M.N. Doja is Professor in the Department of Computer Engineering, Faculty of Engineering and Technology, Jamia Millia Islamia, New Delhi-110025.",
      profile: "https://iiitsonepat.ac.in/director-s-profile",
      Country: "India",
      image: Doja_sir
    },

    {
      proffessor: "Prof. Gadadhar Misra",
      designation: "Professor",
      University: "Indian Institute of Technology, Gandhinagar",
      State: "Gujarat",
      description: "Gadadhar Misra obtained his BSc from Utkal University (1977), MSc in Mathematics from Sambalpur University (1979), and PhD from SUNY, Stony Brook (1982). He served as Assistant Professor at the University of Georgia, Athens (1982-84), and the University of California, Irvine (1984-85), then at the Indian Statistical Institute (ISI) (1986-89). He became Associate Professor (1989-93) and then Professor at ISI, Bangalore (1993-2007). He joined the Indian Institute of Science, Bangalore as a Professor in 2007 and is currently a visiting professor at IIT Gandhinagar.",
      profile: "https://iitgn.ac.in/faculty/maths/fac-gadadhar",
      Country: "India",
      image: gadadhar_sir
    },

    // {
    //   proffessor: "Dr. Rafik Hamza",
    //   designation: "Associate Professor",
    //   University: "Tokyo International University (TIU), Tokyo ",
    //   State: "Jalandhar",
    //   Country: "Japan",
    //   image: Rafik_sir
    // },
   

    // {
    //   proffessor: "Prof. Binod Kumar Kanaujia",
    //   designation: "Honourable Director",
    //   University: "Dr. B R Ambedkar National Institute of Technology ",
    //   State: "Jalandhar",
    //   description: "Binod Kumar Kanaujia (Senior Member, IEEE) received the B.Tech. degree in electronics engineering from the Kamla Nehru Institute of Technology (KNIT), Sultanpur, India, in 1994, and the M.Tech. and Ph.D. degrees from the Department of Electronics Engineering, IIT (Banaras Hindu University) Varanasi, Varanasi, India, in 1998 and 2004, respectively. He has been a Professor and the Dean of the School of Computational and Integrative Sciences, Jawaharlal Nehru University (JNU), New Delhi, India.",
    //   profile: "https://nitj.irins.org/profile/60657",
    //   Country: "India",
    //   image: binod_sir
    // },

    {
      proffessor: "Prof. Shehzad Ashraf Chaudhry",
      designation: "Professor",
      University: "Abu Dhabi University (ADU)",
      State: "Dehradun",
      description: "Shehzad Ashraf Chaudhry (Member, IEEE) received the M.S. and Ph.D. degrees (with distinction) in computer science from the International Islamic University, Islamabad, Pakistan, in 2009 and 2016, respectively.,He is currently an Associate Professor of Cybersecurity Engineering with the Department of Computer Science and Information Technology, College of Engineering, Abu Dhabi University, Abu Dhabi, UAE.",
      profile: "https://ieeexplore.ieee.org/author/38243873600",
      Country: "UAE",
      image: Sehzad_sir
    },

  
    {
      proffessor: "Prof. Sanjay Jasola",
      designation: "Honourable Director General",
      University: "Graphic Era Deemed to be University",
      State: "Dehradun",
      description:"Prof. Sanjay Jasola is currently serving as Vice Chancellor, Graphic Era Hill University. Prof. Jasola was Dean (Academics), Dean (School of ICT) in Gautam Buddha University, Greater NOIDA. He has more than three decades of experience in teaching, research and academic administration. He has also served as faculty member in Wawasan Open University, Penang, Malaysia and as Deputy Director in IGNOU, New Delhi. ",
      profile: "https://geu.ac.in/about-us/director-general/",
      Country: "India",
      image: Sanjay
    }, 

    
    // {
    //   proffessor: "Dr. Mohammad Shojafar",
    //   designation: "Associate Professor",
    //   University: "University of Surrey, Guildford",
    //   State: "Dehradun",
    //   Country: "United Kingdom",
    //   image: mahmud_sir
    // },
    // {
    //   proffessor: "Dr. Arijit Karati",
    //   designation: "Assistant Professor",
    //   University: "National Sun Yat-sen University,",
    //   State: "Dehradun",
    //   Country: "Taiwan",
    //   image: Arijit_sir
    // },
    // {
    //   proffessor: "Dr. Khalid Mahmood",
    //   designation: "Assistant Professor",
    //   University: "National Yunlin University of Science and Technology, Yunlin",
    //   State: "Dehradun",
    //   Country: "Taiwan",
    //   image: Khalid_sir
    // },
    // {
    //   proffessor: "Dr. Madhusanka Liyanage",
    //   designation: "Associate Professor",
    //   University: "University College Dublin",
    //   State: "Dehradun",
    //   Country: "Ireland",
    //   image: Liyange_sir
    // },
    // {
    //   proffessor: "Dr. Dheerendra Mishra",
    //   designation: "Assistant Professor",
    //   University: "Maulana Azad National Institute of Technology ",
    //   State: "Dehradun",
    //   Country: "India",
    //   image: Dherendar_sir
    // },
    
  ]
  return (
    <div className="container my-5">
    <div className="row">
      <div className="col-sm-6 mb-3 mb-sm-0">
      <h1 className="card-title border-bottom">Keynote Speakers</h1>

      <div className="row row-cols-1 row-cols-md-2 g-4 my-4">
      

      {speaker.map((e,index)=>(
        <div className="col" key={index}>
          <div className="card text-center h-100 shadow">
            <img key={index} src={e.image} className="card-img-top rounded-circle mx-auto d-block my-3" alt="..." style={{height:"50%", width:"50%",borderRadius:"50%"}} />
            <div className="card-body">
              <h5 className="card-title">{e.proffessor}</h5>
              <p className="card-text">{e.designation}</p>
              <p className="card-text">{e.University}</p>
              <p className="card-text">{e.Country}</p>
              <p className="card-text" style={{textAlign:"justify"}}>{e.description.slice(0,200)}.....</p>
              <a type="button" className="btn btn-outline-primary" href={e.profile} target="_blank">Visit Profile</a>
            </div>
          </div>
        </div>
        ))}
      </div>
      

        {/* <div className="card border-0">
              <div className="card-body">
                <h1 className="card-title border-bottom">Keynote Speakers</h1>
                
                

                {speaker.map((e,index)=>(

                    <SpeakerCard name={e.proffessor} designation={e.designation} university={e.University} state={e.State} country={e.Country} image={e.image} />



                ))}
                
                
              </div>
            </div> */}

            
          </div>

          <Ieee_banner />


          
    </div>
  </div>
  )
}

export default Speakers