import React from 'react'
import Ieee_banner from '../Ieee_banner/Ieee_banner'

function Shedule() {
  return (
    <div className="container my-5">
    <div className="row">
      <div className="col-sm-6 mb-3 mb-sm-0">
            <div className="card border-0">
              <div className="card-body">
                <h1 className="card-title border-bottom">Program Schedule</h1>
                <h3 className="card-text">Comming soon......</h3>
                
              </div>
            </div>
          </div>

          <Ieee_banner />


          
    </div>
  </div>
  )
}

export default Shedule