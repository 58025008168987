import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Titlebar from './components/TitleFolder/Titlebar';
import Imageslider from './components/Slider/Imageslider';
import { BrowserRouter, Routes, Route  } from 'react-router-dom';
import Home from './components/Home/Home';
import Special_session from './components/Special_session/Special_session';
import Speakers from './components/Speakers/Speakers';
import Submission from './components/Paper_submission/Submission';
import Shedule from './components/Shedule/Shedule';
import Registration from './components/Registration/Registration';
import Contacts from './components/Contacts/Contacts';
import Footer from './components/Footer/Footer';
import Steering from './components/Committee/Steering';
import Organize from './components/Committee/Organize';
import Prog from './components/Committee/Program'
import Tracks from './components/Tracks/Tracks';
import Guideline from './components/Guidelines/Guideline';
import Archieve from './components/Archieve/Archieve';

function App() {
  return (
    <>
    <BrowserRouter>
        <Titlebar />
        <Navbar />
        <Imageslider />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="special" element={<Special_session />} />
          <Route path="speakers" element={<Speakers />} />
          <Route path="submission" element={<Submission />} />
          <Route path="Shedule" element={<Shedule />} />
          <Route path="registration" element={<Registration />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="Committee" element={<Steering/>}/>
          {/* <Route path="OrganizingCommittee" element={ <Organize/>} />
          <Route path="ProgramCommittee" element={ <Prog/> } /> */}
          <Route path="tracks" element={ <Tracks/> } />
          <Route path="guideline" element={<Guideline/>} />
          <Route path="archieve" element={<Archieve/>} />
            
             
        </Routes>
        <Footer />
    </BrowserRouter>  
    </>
  );
}

export default App;
