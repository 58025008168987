import React from 'react'
import Ieee_banner from '../Ieee_banner/Ieee_banner'
import application from './Proposal_Application.docx'

function Special_session() {
  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-sm-6 mb-3 mb-sm-0">
              <div className="card border-0">
                <div className="card-body">
                  <h1 className="card-title">Call for Special Sessions</h1>

                  <h4 className="card-title border-bottom my-4">Invitation to conduct special session in NetCrypt 2025</h4>

                  {/* <h5 className=" card-text my-4">Dear Sir/Mam</h5> */}

                  <p className="card-text my-4">Greeting from NetCrypt-2025!</p>

                  <p className="card-text my-4" style={{textAlign: "justify"}}>NetCrypt Conference team invites you to organize the special session in the International Conference on Data Analytics and Management (NetCrypt-2025), to be held at The Jawaharlal Nehru University, New Delhi India on 29th - 31st May 2025 of your research domain. We are diligently working to bring some of the most knowledgeable researchers from all over the world along with the leaders from the industry to explore the important topics of research. The three day conference will include workshop, technical sessions and keynotes on cutting edge technologies related to our industry. The URL for the conference: www.netcrypt.org.in Kindly increase the value of the conference by being part of this conference as a session chair and heading your own session in this esteemed conference. We are aware about your networking skills and of having an eye to identify quality research papers.</p>

                  <h4 className="card-text my-4">Highlights of the conference and Special Session Benefits:</h4>

                  <ol>
                    {/* <li className="my-2" style={{textAlign:'justify'}}>Proceedings in the reputed Scopus indexed Springer series (Approved).</li> */}
                    {/* <li className="my-2" style={{textAlign:'justify'}}>You will get 50% discount on any two selected papers.</li> */}
                    <li className="my-2" style={{textAlign:'justify'}}>The minimum number of registrations should be 10.</li>

                    {/* <li className="my-2" style={{textAlign:'justify'}}>Extended papers in SCI/Scopus/ESCI/DBLP/ACM Digital Library journals. We have already got approval from some Scopus and high quality journals. (Soon will be declared).</li> */}

                    <li className="my-2" style={{textAlign:'justify'}}>
                    Extended papers expected to go to IEEE xplore, subject to getting screened through technical program. </li> 

                    

                    <li className="my-2" style={{textAlign:'justify'}}>Opportunity to be Session chair at the conference.</li>
                    {/* <li className="my-2" style={{textAlign:'justify'}}>Extra weightage and support will be provided for your session extended paper in SCI/ Scopus Journal.</li> */}
                    <li className="my-2" style={{textAlign:'justify'}}>You will get the opportunity to listen international and national key note speakers in the conference.</li>
                    <li className="my-2" style={{textAlign:'justify'}}>Easy accessibility of the well-connected conference venue.</li>
                  </ol>

                  <p className="card-text my-4" style={{textAlign:'justify'}}>If you are interested to conduct a special session, kindly mail us the special session proposal at chair@netcrypt.org.in latest by 30th January, 2025 as per the format attached with this mail.</p>

                  <p className="card-text my-4" style={{textAlign:"justify"}}><strong>All the accepted papers are expected to be submitted to IEEE Xplore after passing the Technical Program Integrity check upon presentation in the Conference, following acceptance after peer review.</strong> </p>

                  <p className="card-text my-4" style={{textAlign:'justify'}}>Looking to see you in NetCrypt-2025.</p>
                  <p className="card-text my-4" style={{textAlign:'justify'}}>Sincerely,</p>
                  {/* <p className="card-text my-4" style={{textAlign:'justify'}}>International Journal of Computer Applications [Impact Factor: 0.814]</p> */}
                  <p className="card-text my-4" style={{textAlign:'justify'}}>Organizer and Convener</p>
                  <p className="card-text my-4" style={{textAlign:'justify'}}>NetCrypt-2025</p>

                  <div className="my-5">
                      <a href={application} type="button" class="btn btn-outline-primary" download="Application_netcrypt">Special Session CFP Template</a>
                      
                      {/* <button type="button" className="btn btn-outline-primary">DOWNLOAD</button> */}
                  </div>
                  
                </div>
              </div>

              
            </div>

            <Ieee_banner />


            
      </div>
    </div>
  )
}

export default Special_session