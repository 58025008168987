import React from 'react'
import Img0 from '../Images/_DSC0012.png'
import Img1 from '../Images/Img1.png'
import Img2 from '../Images/IMG_5140.png'
import Img3 from '../Images/_DSC0049.png'
import Img4 from '../Images/IMG_6318.png'
import Img5 from '../Images/IMG_001.png';
import Img6 from '../Images/IMG_002.png';
import Img7 from '../Images/IMG_003.png';
import Img8 from '../Images/IMG_004.png';


function Imageslider() {
  return (
    <div className="container">
        <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">

                <div className="carousel-item active" data-bs-interval="12000">
                    <img src={Img0} className="d-block w-100" alt="..." height= "400px" width="1200px"  />
                </div>

                <div className="carousel-item" data-bs-interval="10500">
                    <img src={Img5} className="d-block w-100" alt="..." height= "400px" width="1200px"  />
                </div>

                <div className="carousel-item" data-bs-interval="9000">
                    <img src={Img6} className="d-block w-100" alt="..." height= "400px" width="1200px"  />
                </div>

                <div className="carousel-item" data-bs-interval="7500">
                    <img src={Img7} className="d-block w-100" alt="..." height= "400px" width="1200px"  />
                </div>

                <div className="carousel-item" data-bs-interval="6000">
                    <img src={Img8} className="d-block w-100" alt="..." height= "400px" width="1200px"  />
                </div>

                <div className="carousel-item" data-bs-interval="4500">
                    <img src={Img1} className="d-block w-100" alt="..." height= "400px" width="1200px"  />
                </div>

                <div className="carousel-item" data-bs-interval="3000">
                    <img src={Img3} className="d-block w-100" alt="..." height= "400px" />
                </div>

                <div className="carousel-item" data-bs-interval="1500">
                    <img src={Img4} className="d-block w-100" alt="..." height= "400px" width="1200px"  />
                </div>

                <div className="carousel-item">
                    <img src={Img2} className="d-block w-100" alt="..."  height= "400px" />
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    </div>
  )
}

export default Imageslider