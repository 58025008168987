import React from 'react'
import Ieee_banner from '../Ieee_banner/Ieee_banner'
import NC from '../Images/netcrypt.drawio.png'

function Submission() {
  return (
    <div className="container my-5">
    <div className="row">
      <div className="col-sm-6 mb-3 mb-sm-0">
            <div className="card border-0">
              <div className="card-body">
                <h1 className="card-title">Call For Paper</h1>
                <h5 className="card-title border-bottom">International Conference on Networks and Cryptology</h5>
                <p className="card-text" style={{textAlign:'justify'}}> NetCrypt 2025 invites original contributions based on the results of research and developments. Prospective authors are requested to submit their papers in not more than 6 pages, as PDF prepared in the double column IEEE format. All the accepted and presented papers are expected to go to IEEE Xplore, subject to getting screened through Technical Program Integrity check, upon presentation in the Conference, following acceptance after peer review.</p>

                <h5 className="card-title">
                  Submissions for NetCrypt are welcomed in the following areas:
                </h5>

                <ul>
                  <li>Wireless Sensor Network</li>
                  <li>Wireless Ad Hoc Network</li>
                  <li>Internet of Things</li>
                  <li>Internet of Every Thing</li>
                  <li>Internet of Vehicles</li>
                  <li>Body Area Network</li>
                  <li>Cloud computing</li>
                  <li>Fog Computing</li>
                  <li>Mobile Computing</li>
                  <li>Cellular Network</li>
                  <li>Delay Tolerant Network</li>
                  <li>4G/5G Networks</li>
                  <li>Cognitive Network</li>
                  <li>Satellite Communication</li>
                  <li>Block Chain</li>
                  <li>Cyber Security</li>
                  <li>Cyber Forensic</li>
                  <li>Secure Techniques using AI</li>
                  <li>Delay Tolerant Network</li>

                  
                </ul>
                <p className="card-text">Papers from other topics relevant to conference theme or the special sessions are also welcome. Submission of papers shall be through.</p>
                

                
                
              </div>
            </div>


            <div className="card border-0">
              <div className="card-body">
                <h1 className="card-title">Tracks</h1>
                <h5 className="card-text border-bottom">The computational science technologies we will be including are:</h5>

                <h5 className="card-title my-4">Track 1: Networking and Communication </h5>
                <p className="card-text">Wireless Sensor Network, Wireless ad hoc Network, Body Area Network, Cellular Network, Cognitive Network, Delay Tolerent Network, 4G/5G Networks, Neural Network, Big Data for Networks, IOT, Internet of Everything, Internet of Vehicles, Trust Management, Satellite Communication, Group Communication, Radio Frequency Identification(RFID).</p>

                {/* <h5 className="card-title my-4">Track 2: Internet of Things: </h5>
                <p className="card-text">IOT, Internet of Everything, Internet of Vehicles, Trust Management.</p> */}

                

                <h5 className="card-title my-4">Track 2: Computing</h5>
                <p className="card-text">Cloud Computing, Distributed Computing, FOG Computing, Mobile Computing, Neuro Communication.</p>

                <h5 className="card-title my-4">Track 3: Cryptography</h5>
                <p className="card-text">Cryptographic Algorithms, Quantum Cryptography, Public Key Infrastructure (PKI), Authentication, Stream Cipher, Block Cipher, Key Management.</p>

                <h5 className="card-title my-4">Track 4: Blockchain</h5>
                <p className="card-text">Consensus Mechanism, Scalability Solutions, Privacy and Security, Blockchain in Supply Chain Management, Blockchain in Healthcare, Blockchain in Internet of Things (IoT), Blockchain in Real Estate.</p>

                {/* <h5 className="card-title my-4">Track 5: Communication </h5>
                <p className="card-text">Satellite Communication, Group Communication, Radio Frequency Identification(RFID)</p> */}

                {/* <h5 className="card-title my-4">Track 6: Encryption </h5>
                <p className="card-text">Public Key Infrastructure (PKI), Authentication, Stream Cipher, Block Cipher, Key Management.</p> */}

                {/* <h5 className="card-title my-4">Track 5: AI Based Security </h5>
                <p className="card-text">Cyber Security, Web Security, Android Security, Fuzzy Logic Based Information, Security Systems, Honey Pot, Cyber Forencics, Vulneribility Assesment, Attacks and Defenses.</p> */}

                <h5 className="card-title my-4">Track 5: Cyber Security </h5>
                <p className="card-text">Cyber Threat Intelligence, Identity and Access Management, Cybersecurity Governance and Risk Management, Digital Forensics and Incident Response, Cyber security in Critical Infrastructure.</p>

                <h5 className="card-title my-4">Track 6: AI Based Security </h5>
                <p className="card-text">Cyber Security, Web Security, Android Security, Fuzzy Logic Based Information, Security Systems, Honey Pot, Cyber Forencics, Vulneribility Assesment, Attacks and Defenses.</p>
                
                
              </div>
            </div>
          </div>

          <Ieee_banner />


          
    </div>
  </div>
  )
}

export default Submission