import React from 'react'
import Ieee_banner from '../Ieee_banner/Ieee_banner'
import Format from './Conference-template-A4.doc'

function Guideline() {
  return (
    <div className="container my-5">
    <div className="row">
      <div className="col-sm-6 mb-3 mb-sm-0">
            <div className="card border-0">
              <div className="card-body">
                <h1 className="card-title border-bottom">Guidelines</h1>
                <h4 className="card-text">Important Points for manuscript prepration</h4>

                <ul >
                      <li className='my-3' style={{textAlign:'justify'}}>Paper must be an unpublished novel research work and it should be written in either British or American style English but not on both.</li>

                      <li className='my-3' style={{textAlign:'justify'}}>Anyone author should be assigned as a Corresponding Author for any future communication with the conference. The number of authors in a manuscript should not exceed 6.</li>

                     <li className='my-3' style={{textAlign:'justify'}}>Full paper submissions should contain 6-8 pages.</li>

                     <li className='my-3' style={{textAlign:'justify'}}>Avoid including affiliation/acknowledgement footnotes. If it cannot be avoided, insert them at the end of the text, just above the references.</li>

                     <li className='my-3' style={{textAlign:'justify'}}>Any tables or figures must be numbered and it should be of high quality.</li>

                     <li className='my-3' style={{textAlign:'justify'}}>Authors should ensure that the article has been thoroughly reviewed for typographical and grammatical mistakes before submitting it. If English is not your first language, have a native English speaker to proofread your paper. Papers will be returned if the level of English is deemed insufficient for publication.</li>

                     <li className='my-3' style={{textAlign:'justify'}}>All the articles will be submitted through CMT ( <a href="https://cmt3.research.microsoft.com/NETCRYPT2025" class="link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" target="_blank">Link</a> ). However, if anyone encounters any problem while submission may contact at chair@netcrypt.org.in. All the accepted and presented papers of NetCrypt 2025 will be submitted for inclusion into IEEE Xplore subject to meeting IEEE Xplore’s scope. ** Indexing: Scopus</li>

                </ul>

                <h4 className="card-text">Paper Formatting</h4>

                <ul >
                      <li className='my-3' style={{textAlign:'justify'}}>Articles may be edited for clarity and grammatical accuracy, and it should be copy edited according to the publisher manuscript style.</li>

                      <li className='my-3' style={{textAlign:'justify'}}>Papers must be written in a Times New Roman font, single spacing; justified alignment according to the aforementioned template.</li>

                     <li className='my-3' style={{textAlign:'justify'}}>Each Paper has to be broken down into the following sections when you submit:

                        <ol>
                            <li className="my-3">Abstract</li>
                            <li className="my-3">Background, Motivation and Objective</li>
                            <li className="my-3">Statement of Contribution/Methods</li>
                            <li className="my-3">Results, Discussions and Conclusion</li>
                            <li className="my-3">Future Research Direction</li>
                        </ol>
                     </li>

                     <li className='my-3' style={{textAlign:'justify'}}>The manuscript should be prepared in IEEE format using MS WORD</li>

                     <li className='my-3' style={{textAlign:'justify'}}>The figures / graphs / plots in the manuscript MUST be of good resolution (600 dpi or more), tables MUST NOT be in pictorial format. Text in figures should not be too small, and preferably of equal size as text of the article.</li>

                     <li className='my-3' style={{textAlign:'justify'}}>Figures, text, or the tables MUST be visible within boundary of the text area of the page and MUST NOT go beyond it.</li>

                     <li className='my-3' style={{textAlign:'justify'}}>Do NOT use any Social / Academic titles (e.g. Mr.,Ms.,Dr.,Prof. etc) preceding the author names. Do NOT mention the position of a person (e.g., research scholar, student, assistant professor, professor, IEEE member, IEEE student member etc.) in the affiliation.</li>

                     <li className='my-3' style={{textAlign:'justify'}}>Mention full address, affiliation, and email of ALL authors, specify a corresponding author with the corresponding e-mail ID. [Once an article is accepted, the publisher will send the proofreading of article to this e-mail]. Articles must be written in spell checked and grammatically correct English.</li>

                     <li className='my-3' style={{textAlign:'justify'}}>All references, figures, and tables should be numbered in sequence starting from 1 and MUST be duly cited / referred within the text.</li>

                     <h5><a href={Format} class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" download="netcrypt_format">Download Paper Template (Microsoft Word)</a></h5>

                </ul>

                <h4 className="card-text my-4">Review Process</h4>

                <ul >
                      <li className='my-3' style={{textAlign:'justify'}}>To ensure high scientific quality, all papers will be reviewed by the Scientific Committee Members.</li>

                      <li className='my-3' style={{textAlign:'justify'}}>Once the reviewing process is complete, the corresponding author of each paper will be notified of the acceptance/revision/rejection by e-mail. The authors are required to follow the reviews in order to improve their paper before their final submission.</li>

                </ul>

                <h4 className="card-text my-4">Notification of Acceptance or Rejection</h4>

                <ul >
                      <li className='my-3' style={{textAlign:'justify'}}>On or before the scheduled date, the organizing committee will notify the corresponding author of the acceptance or rejection of the paper to the conference program. When the paper is accepted in NetCrypt, the corresponding author is encouraged to update the paper to meet any recommendations provided by the reviewers.</li>

                      <li className='my-3' style={{textAlign:'justify'}}>Rejection may be due to different reasons: Out of Scope, high plagiarism. The paper once rejected by the conference committee will never be considered for the conference. Authors of the rejected paper should avoid making new submission with the already rejected paper.</li>

                </ul>

                <h4 className="card-text my-4">Academic Ethics and Conference Policy:</h4>

                <ul >
                      <li className='my-3' style={{textAlign:'justify'}}>The authors should abide to the guidelines mentioned below before submitting the paper, if any dispute arises during peer review or after acceptance and publication and if authors are unable to give a valid explanation, NetCrypt retains the right to remove a submission from the editing process.</li>
                </ul>

                <h4 className="card-text my-4">Plagiarism</h4>

                <ul >
                      <li className='my-3' style={{textAlign:'justify'}}>Acceptable plagiarism range is less than 10% (excluding references) with 0% AI Plagiarism.</li>
                      <li className='my-3' style={{textAlign:'justify'}}>Plagiarism, image manipulation, and data fabrication are not tolerated.</li>
                      <li className='my-3' style={{textAlign:'justify'}}>Plagiarism includes copying research ideas, text, images, or data from another source/research literature, even from the author’s own publications without giving any credit to the original source.</li>
                      <li className='my-3' style={{textAlign:'justify'}}>Text copied from another source must be reused within quotations, and the original source must be referenced. If the design of a research, or the structure or language of the publication, was inspired by previous research, the corresponding works must be explicitly cited.</li>
                      <li className='my-3' style={{textAlign:'justify'}}>If plagiarism is detected during the peer review process, the manuscript will be rejected. If plagiarism is detected after publication, we will retract the paper.</li>
                </ul>

                <h4 className="card-text my-4">Copyright</h4>

                <ul >
                      <li className='my-3' style={{textAlign:'justify'}}>As an author/editor of a work, you may be responsible for overseeing the inclusion of third party content. By ‘third party content’ we mean any work that you have not created yourself and which you have reproduced or adapted from other sources.</li>

                      <li className='my-3' style={{textAlign:'justify'}}>Authors remain fully responsible for ensuring that they have obtained, documented and paid for (when applicable), all necessary rights to use third party content in their works. If authors chose to reuse third party content from a previous edition of their work, they are also responsible for ensuring they obtained permission from the third party for new editions.</li>
                </ul>

                <h4 className="card-text my-4">Note:</h4>

                <ul >
                      <li className='my-3' style={{textAlign:'justify'}}>In no case organizing institute or any of the organizing team members will be responsible, if the article is rejected at any phase due to quality issues, before or after acceptance.Additionally, please note that in order to promote offline presentations, only presenters who are presenting offline will be provided with the kit.</li>
                </ul>

                
              </div>
            </div>
          </div>

          <Ieee_banner />


          
    </div>
  </div>
  )
}

export default Guideline