import React from 'react'
import Ieee_banner from '../Ieee_banner/Ieee_banner'

function Home() {
  return (


   <div className="container my-5">

        <div className="row">
          <div className="col-sm-6 mb-3 mb-sm-0">
            <div className="card border-0">
              <div className="card-body">
                <h1 className="card-title border-bottom">About the Conference</h1>
                <p className="card-text" style={{textAlign:'justify'}}>The International Conference on the Network and Cryptology (NetCrypt) with machine learning is focusing on all aspects of cryptology, network security, privacy, and cyber security, attracting cutting-edge results from world-renowned scientists in the area. The objective of NetCrypt 2025 is to provide a platform for researchers, engineers, academicians as well as industrial professionals from all over the world to present their research results and development activities in Communications and Network Engineering. This conference provides opportunities for the delegates to exchange new ideas and application experiences face to face, to establish business or research relations and to find global partners for future collaboration. It aims to provide a forum for researchers, engineers and practitioners interested in the application of information, networks and communication to discourse on research and development, professional practice in related fields. </p>

                <h5 className="card-title border-bottom">Scope</h5>
                <p className="card-text" style={{textAlign:'justify'}}>
                Networks are currently witnessing a significant transformation that is resulting in new ways to address the deployment of applications and services in a digital society. Over the years, the focus of the conference has shifted from raising awareness towards sharing security event histories and discussing solutions and protection strategies. The main objective of this conference is to emphasize the recent technological developments in broadband high-speed networks, peer-to-peer networks, wireless and mobile networks which have led to new challenging problems, such as providing QoS support to the emerging multimedia applications across both wired and wireless networks. It will serve as a forum for researchers from both electronics engineering and computer science to present latest research results on QoS, Authentication,  reliability, Trust management,  security and robustness issues for both wired and wireless networks with the hope that Computer and Electronics practitioners will work together to develop networks methodologies. The conference will feature prominent invited speakers as well as papers by top researchers in the field from all over the world.
                </p>

                {/* <h5 className="card-title border-bottom">Topics</h5>
                <p className="card-text" style={{textAlign:'justify'}}>
                In order to address and solve many of the tough challenges in Networking, prospective authors are cordially invited to submit their original and unpublished research contributions to this event on the following technical areas of (but not limited to) Network communications and IoT.
                </p>
                <ul>
                  <li>Cryptography and Network security </li>
                  <li>Cyber Security </li>
                  <li>Trust Management </li>
                  <li>	Attacks and Defenses</li>
                  <li>Authentication</li>
                  <li>key Management</li>
                  <li>Reliability and Robustness </li>
                  <li>Cyber Physical System </li>
                  <li>	Internet Of Thing (Iot)</li>
                  <li>	Body Area Network </li>
                  <li>	Multicasting </li>
                  <li>	RFID</li>
                  <li>	Vehicular Ad-Hoc Network and Named Data Network</li>
                  <li>	Reliability, availability, resiliency, and robustness of smart grid</li>
                  <li>	Block Chain, Security and Privacy : Metrics and Policies</li>
                  <li>	Wireless Sensor Networks </li>
                  <li>	Wireless Local Area Network</li>
                  <li>	Software-defined Networks(SDN) </li>
          

                </ul> */}
                
              </div>
            </div>
          </div>

          <Ieee_banner />

          

        </div>
       {/* <div className="row">


         <div className="col-6 mx-5 container">
           <h2 className="head">About the Conference</h2>
           <blockquote className="blockquote s-12 m-7 l-8" style={{display: 'flex',justifyContent: 'stretch', width: `${window.innerWidth<=640?"290":"570"}px`}}>

               <p className="lead text-break" style={{textAlign:"justify"}}> <small>The 2nd International Conference on Disruptive Technologies (ICDT-2024) will provide an outstanding international venue for exchanging knowledge and achievements in Disruptive Technology using AI, Blockchain, IoT, Augmented reality, 3D Printing & ML with their Theory, Methodology, and applications. The conference seeks contributions in both theoretical and practical aspects to the multi-disciplines of Artificial Intelligence, Machine Learning and Data Analytics.
               The Conference's goal is to bring together scholars and practitioners from academia and industry to discuss and exchange cutting-edge developments in the field. Articles illustrating research results, projects, surveying works, and industrial experiences that describe significant advances in the areas of Disruptive Technologies are encouraged to be submitted to the conference. Authors, research scholars and industry practitioners are solicited to contribute to the conference by submitting their original, unpublished high quality work that describe the significant advancement in the area of Information retrieval, IoT, Artificial Intelligence, Mechatronics, data visualization but not limited to</small> 
               </p>
           </blockquote>

         </div>

         <div className="col-4 mx-5">
           <h2 className="head">Publishing and Indexing</h2>
         <blockquote className="blockquote" style={{ display: 'flex', justifyContent: 'stretch',width: `${window.innerWidth<=640?"290":"570"}px` }}>
         <p className="lead text-break" style={{textAlign:"justify" }}>
           <small>
            Accepted and presented papers will be submitted for inclusion into IEEE Xplore subject to meeting IEEE Xplore’s scope and quality requirements
           </small>
          </p>
         </blockquote>


        </div>


      </div> */}
   </div>
  )
}

export default Home