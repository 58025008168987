import React from 'react'

function Footer() {
  return (
    <footer className=" text-white py-4" style={{backgroundColor:"#152732"}}>
        <div className="container text-center">
            <p>&copy; 2025 JAWAHARLAL NEHRU UNIVERSITY, NEW DELHI. All rights reserved.</p>
        </div>
    </footer>


    
  )
}

export default Footer