import React from 'react'
// import JNU from '../Images/IMG-20231204-WA0000.jpg'
// import NC from '../Images/netcrypt.drawio.png'
import { Link } from 'react-router-dom'

function Ieee_banner() {
  return (
    <div className="col-sm-6 mb-3 mb-sm-0">
            <div className="card border-0">
              <div className="card-body">
                <h1 className="card-title border-bottom">Indexing and Publishing</h1>
                <p className="card-text" style={{textAlign:'justify'}}>Accepted and presented papers are expected to go to IEEE Xplore, subject to getting screened through Technical Program Integrity check, upon presentation in the Conference, following acceptance after peer review.</p>
                
              </div>
            </div>

            <div className="card shadow mb-3">
                    <div className="card-body">
                        <h5 className="card-title border-bottom my-4">Submit your paper</h5>
                        <p className="card-text">Click on the bellow link to submit your paper</p>
                        <a href="https://cmt3.research.microsoft.com/NETCRYPT2025" type="button" class="btn btn-primary border-0" target="_blank" style={{background:"rgb(0,126,123)"}}>Submit Your paper</a>

                        <p className="card-text my-3">Click on the below link to get the steps for submission.</p>
                        <Link to="/tracks" type="button" class="btn btn-outline-primary">How to Submit</Link>

                        <h5 className="card-title border-bottom my-4">Important Dates</h5>
                        {/* <p className="card-text"> <strong>Abstract Submission Last Date:</strong> </p>
                        <p className="card-text">15 th November, 2023</p> */}
                        <p className="card-text"> <strong>Full Paper Submission Last Date:</strong> </p>
                        <p className="card-text"> 31<sup>st</sup> December, 2024</p>

                        {/* <p className="card-text"> <strong>Special Session / Workshop</strong> </p>
                        <p className="card-text"> 25 <sup>th</sup> December, 2024</p> */}

                        <p className="card-text"> <strong>Notification for Paper Acceptance Last Date:</strong> </p>
                        <p className="card-text"> 15<sup>th</sup> March, 2025</p>

                        <p className="card-text"> <strong>Camera Ready Submission:</strong> </p>
                        <p className="card-text"> 10<sup>th</sup> April, 2025</p>

                        {/* <p className="card-text"> <strong>Last date of author/participant's Registration:</strong> </p>
                        <p className="card-text"> 25 <sup>th</sup> March, 2025</p> */}
                        
                        <p className="card-text"> <strong>Conference Dates:</strong> </p>
                        <p className="card-text">29<sup>th</sup>- 31<sup>st</sup> May, 2025</p>
                    </div>
            </div>
    </div>
  )
}

export default Ieee_banner