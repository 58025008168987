import React from 'react'
import Ieee_banner from '../Ieee_banner/Ieee_banner'
import Foreword2019 from './Foreword-2019.pdf'
import Foreword2020 from './Foreword-2020.pdf'

function Archieve() {
  return (
    <div className="container my-5">
    <div className="row">
      <div className="col-sm-6 mb-3 mb-sm-0">
            <div className="card border-0">
              <div className="card-body">
                <h1 className="card-title border-bottom">Previous Conference</h1>
                
                <div className="container my-5">
                    <h3 className="card-title border-bottom">NetCrypt 2019</h3>

                    <p className="card-text my-4" style={{textAlign:"justify"}}>The International Conference on Networks and Cryptology (NetCrypt) is a recognized conference, focusing on all aspects of cryptology, including data, network, computer security, cybersecurity, privacy, and data protection attracting cutting-edge results from world-renowned scientists in the area. The mission of the NetCrypt conference series is to provide presentation, discussion, and a reputable publication forum in computer science and information systems. The forum invites researchers and practitioners from around the world to contribute their research results focused on their scientific and professional interests in a chosen conference track. The conference calls for various special issues for publication of selected papers. One special issue on “Networks and Cryptology” is being published in Journal of Discrete Mathematical Sciences & Cryptography.</p>

                    <p className="card-text" style={{textAlign:"justify"}}>The conference received 100 papers for publication for this special issue. Out of these 18 have been selected after following a 2-tier review system.</p>

                    <a type="button" class="btn btn-outline-success mx-3" href={Foreword2019} download="Foreword_2019">Foreword letter</a>
                    <a type="button" class="btn btn-outline-primary mx-3" href="https://www.tandfonline.com/toc/tdmc20/22/8" target="_blank">Published Paper</a>

                </div>

                <div className="container">

                    <h3 className="card-title border-bottom">NetCrypt 2020</h3>

                    <p className="card-text my-4" style={{textAlign:"justify"}}>With the advent of the World Wide Web and the emergence of e-commerce applications and social networks, organizations worldwide generate a large amount of data daily. Data security is the utmost critical issue in ensuring the safe transmission of information through the internet. Also, network security issues are now becoming important as society is moving towards the digital information age. As more and more users connect to the internet, there is an upsurge in cyber-crimes. It comprises authorization of access to information in a network, controlled by the network administrator. The task of network security requires ensuring the security of end systems and the entire network.</p>

                    <p className="card-text my-4" style={{textAlign:"justify"}}>The NetCrypt 2020 received 120 research papers from all over the world related to the recent trends in computer science focusing on Networks, Cryptology, Network security, Privacy, Cyber Security and Artificial Intelligence. At least three reviewers carefully reviewed all the papers. The best selected 31 high-quality research papers are considered for publication in this special issue of Journal of Discrete Mathematical Sciences and Cryptography.</p>

                    <a type="button" class="btn btn-outline-success mx-3" href={Foreword2020} download="Foreword_2020">Foreword letter</a>
                    <a type="button" class="btn btn-outline-primary mx-3" href="https://www.tandfonline.com/toc/tdmc20/24/5" target="_blank">Published Paper</a>

                </div>
                
              </div>
            </div>
          </div>

          <Ieee_banner />


          
    </div>
  </div>
  )
}

export default Archieve